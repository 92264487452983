// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-page-query-js": () => import("./../../../src/templates/page-query.js" /* webpackChunkName: "component---src-templates-page-query-js" */),
  "component---src-templates-post-query-js": () => import("./../../../src/templates/post-query.js" /* webpackChunkName: "component---src-templates-post-query-js" */)
}

